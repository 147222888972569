import React from 'react';
import styled from 'styled-components';
import arrow from '../../assets/images/arrow.svg';

const TitleStyles = styled.div`
  grid-column: span 12;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    grid-column: 3 / span 4;
  }
  @media screen and (min-width: 64em) {
    grid-column: 1 / span 3;
  }
`;
const SignupStyles = styled.div`
  grid-column: span 12;
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    grid-column: span 5;
  }
  @media screen and (min-width: 64em) {
    grid-column: 5 / span 9;
  }
  display: flex;
  align-items: flex-end;
  input {
    width: 100%;
    outline: none;
  }
  .submit-btn {
    outline: none;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-position: right;
    height: 30px;
    width: 30px;
    transform: translate(-34px, -6px);
  }
`;

export default function Newsletter({ title }) {
  return (
    <div className="block no-padding-top">
      <TitleStyles>
        <h5>{title}</h5>
      </TitleStyles>
      <SignupStyles>
                       
        <div id="mc_embed_signup"  style={{ width: '100%'}}>
        <form action="https://rizoom.us14.list-manage.com/subscribe/post?u=cf003d913395a6808b43df62b&amp;id=88dfbfe582" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
            <div id="mc_embed_signup_scroll">
        <div className="mc-field-group">
        <input type="email" placeholder="Je emailadres" className="email-input" name="EMAIL" className="required email" id="mce-EMAIL" />
        <input type="submit" value="Inschrijven" name="subscribe" id="mc-embedded-subscribe" className="button"/>
      </div>
        <div id="mce-responses" className="clear foot">
          <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
          <div className="response" id="mce-success-response" style={{ display:'none' }}></div>
        </div>  

        </div>
      </form>
      </div>
      <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
  
      </SignupStyles>
    </div>
  );
}
