import React from 'react';
import styled from 'styled-components';
import 'normalize.css';

const ContentStyles = styled.div`
  max-width: var(--grid-width);
  margin: 0 auto;
  padding: 0px 0px;
  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: repeat(12, 1fr);
  margin-bottom: 15px;
  @media screen and (max-width: 39.9375em) {
    padding-left: var(--mobileContainerMargin);
    padding-right: var(--mobileContainerMargin);
  }
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding-left: var(--mobileContainerMargin);
    padding-right: var(--mobileContainerMargin);
  }
`;

export default function Grid({ children }) {
  return <ContentStyles>{children}</ContentStyles>;
}
